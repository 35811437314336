import { Box, Flex, Text, useDisclosure, VisuallyHidden } from '@chakra-ui/react';
import { BodyWrapper } from 'components/wrapper/bodyWrapper';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { normalize } from 'pages/api/sitemap';
import React, { FunctionComponent, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { withTranslations } from 'store/translations';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';

import { htmlResolver } from '../../helpers/htmlResolver';
import { LinkType } from '../../lib/enums/LinkType';
import { ContentPage } from '../../models';
import { Fact as FactModel } from '../../models/content-types/fact';
import { Link } from '../base/Link';
import { ContactFormModal } from './ContactFormModal';
//import { mode } from '@chakra-ui/theme-tools';

function separateFloats(input: string) {
  const floatRegex = /-?\d+(\.\d+)?/g;
  const floatArr = input.match(floatRegex) || [];
  let finalArr = input;
  floatArr.forEach((num) => {
    finalArr = finalArr.replace(num, `|${num}|`);
  });
  return finalArr.split('|');
}

function removeSuffixedStrings(input: string[]) {
  const filteredArray = [] as Array<string | undefined>;
  for (let i = 0; i < input.length; i++) {
    if (i === 0 || Number.isNaN(parseFloat(input[i - 1]))) {
      filteredArray.push(input[i]);
    } else {
      filteredArray.push(undefined);
    }
  }
  return filteredArray;
}

export enum FactParent {
  FACTBOX = 'factbox',
  TEXT_MODULE = 'text_module',
}

interface FactProps {
  model: FactModel;
  backgroundColor?: string;
  accentColor?: string;
  bodyColor?: string;
  childOf?: FactParent;
}

const MotionText = motion(Text);

const parentStyles = {
  factbox: {
    gap: 'xxs',
  },
  text_module: {
    gap: 'xs',
    p: { base: 'xs', lg: 's' },
  },
};

export const Fact: FunctionComponent<FactProps> = ({
  model,
  backgroundColor,
  accentColor,
  bodyColor,
  childOf = FactParent.FACTBOX,
}) => {
  const router = useRouter();
  const translations = withTranslations();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchItems();
  }, []);
  useEffect(() => {
    translations.isReady && fetchItems();
  }, [router.locale, translations.locale]);
  const factText = model.elements.factAsText.value;
  const numbersHaveSpacing = factText.match(/\d+\s+\d/g);
  const factTextArray = separateFloats(factText.replace(/(\d+)\s+(\d+)/g, '$1$2'));
  const factTextArrayWithoutSufix = removeSuffixedStrings(factTextArray);
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>≤≥/?~]/;

  const description = htmlResolver(model.elements.description);
  const icon = model.elements.factAsIcon.value;
  const iconalt = model.elements.alternativeText.value;
  const compressImage = model.elements.compressFactImage.value ? model.elements.compressFactImage.value : 75;
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  const url = hrefLink(model, (slug as unknown as string)?.split('/'), false, router.locale, translations);
  const fileFrontify = JSON.parse(model.elements.ctaSnippetFrontifyFile.value);
  const ctaSnippetCtaText = model.elements.ctaSnippetCtaText.value;
  const formModalCtaButtonText = model.elements.formModalCtaButtonText.value;
  const ctaSnippetKontentAi = model.elements.ctaSnippetKontentAiUrl.value[0];
  const path = router.asPath.slice(0, router.asPath.lastIndexOf('/'));
  const image = imageFormatter(icon, iconalt, false, compressImage);
  const [ctaSnippetKontentAiUrl, setCtaSnippetKontentAiUrl] = useState<string>();

  const setUrl = async () => {
    if (ctaSnippetKontentAi) {
      const item = (await fetch(`/api/sitemap?codename=${ctaSnippetKontentAi}&locale=${router.locale}`)
        .then((res) => res.json())
        .catch(() => null)) as ContentPage | null;
      if (translations.isReady) {
        return item ? Object.keys(normalize(item, router.locale, undefined, true, translations))?.[0] : '';
      }
    }
  };
  const fetchItems = async () => {
    const url = !router.asPath.includes('/editors-only')
      ? await setUrl()
      : ctaSnippetKontentAi != undefined
      ? `${path}/${ctaSnippetKontentAi}`
      : null;
    url && setCtaSnippetKontentAiUrl(url);
  };
  return (
    <Flex bg={backgroundColor} flexDir='column' sx={parentStyles[childOf]}>
      <VisuallyHidden>{`${translations.aria('fact')}: ${factText}`}</VisuallyHidden>
      {factText ? (
        <Box
          aria-hidden='true'
          color={accentColor}
          whiteSpace={'pre-wrap'}
          overflowWrap='break-word'
          overflow={'hidden'}
          flexWrap='wrap'
          display='inline-flex'
          textStyle={{ base: 'mobile.h1', md: 'desktop.h3' }}
        >
          {specialChars.test(factText) ? (
            <Text w='100%' sx={{ hyphens: 'auto' }}>
              {factText}
            </Text>
          ) : (
            factTextArrayWithoutSufix.map((item, index) => {
              if (!item) return;
              const to = parseFloat(item);

              if (!Number.isNaN(to)) {
                // If over +- 10, count up/down last 10, otherwise count from 0
                const from = to < 10 ? (to < -10 ? to + 10 : 0) : to - 10;
                const decimals = (item.split('.')[1] || []).length;
                const suffix = factTextArrayWithoutSufix[index + 1] ?? factTextArray[index + 1];
                return (
                  <CountUp
                    key={index}
                    enableScrollSpy={true}
                    scrollSpyOnce={true}
                    start={from}
                    end={to}
                    duration={3}
                    decimals={decimals}
                    separator={numbersHaveSpacing ? ' ' : ''}
                    useEasing={true}
                    redraw={true}
                    suffix={suffix}
                  >
                    {({ countUpRef }) => <MotionText ref={countUpRef} />}
                  </CountUp>
                );
              }
              return (
                <Text key={index} w='100%' sx={{ hyphens: 'auto' }}>
                  {item}
                </Text>
              );
            })
          )}
        </Box>
      ) : (
        icon && <Image width={64} height={64} src={image.src} alt={image.alt} quality={image.quality} />
      )}
      {model.elements.headline.value !== '' && (
        <Text color={bodyColor} textStyle={{ base: 'desktop.body.medium.s', lg: 'desktop.body.book.l' }}>
          {model.elements.headline.value}
        </Text>
      )}
      <Box color={bodyColor} textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.l' }}>
        {description?.map((text, index) => (
          <BodyWrapper key={index} sx={index ? { pt: 'xxs' } : {}} tag={text.tag} body={text.body} />
        ))}
      </Box>
      {model.elements.ctaSnippetUrl.value && url ? (
        <Link
          href={url}
          iconName='chevronRightForLink'
          colorScheme={accentColor}
          type={LinkType.SecondaryButton}
          isExternal={model.elements.ctaSnippetOpenIn.value[0]?.codename === 'new_tab'}
          locale={router.locale}
        >
          {ctaSnippetCtaText}
        </Link>
      ) : ctaSnippetKontentAiUrl ? (
        <Link
          href={ctaSnippetKontentAiUrl}
          iconName='chevronRightForLink'
          colorScheme={accentColor}
          type={LinkType.SecondaryButton}
          isExternal={model.elements.ctaSnippetOpenIn.value[0]?.codename === 'new_tab'}
          locale={router.locale}
        >
          {ctaSnippetCtaText}
        </Link>
      ) : fileFrontify ? (
        <Link
          href={fileFrontify.downloadUrl}
          iconName='chevronRightForLink'
          colorScheme={accentColor}
          type={LinkType.SecondaryButton}
        >
          {ctaSnippetCtaText}
        </Link>
      ) : null}
      {model.elements.formModalCtaFormModal.linkedItems[0] && formModalCtaButtonText && (
        <>
          <Link
            href='#'
            iconName='chevronRightForLink'
            colorScheme={accentColor}
            type={LinkType.SecondaryButton}
            locale={router.locale}
            onClick={(e) => {
              e?.preventDefault();
              onOpen();
            }}
          >
            {formModalCtaButtonText}
          </Link>
          <ContactFormModal
            id={`${model.system.id}${model.elements.formModalCtaFormModal.linkedItems[0].system.id}`}
            model={model.elements.formModalCtaFormModal.linkedItems[0]}
            isOpen={isOpen}
            onClose={onClose}
          />
        </>
      )}
    </Flex>
  );
};
